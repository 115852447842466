import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { alpha, useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { NavItem } from './components';

const Topbar = ({ onSidebarOpen, pages, colorInvert = false }) => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const {
    landings: landingPages,
    secondary: secondaryPages,
    company: companyPages,
    account: accountPages,
    portfolio: portfolioPages,
    blog: blogPages,
  } = pages;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={1}
    >
      <Box
        display={'flex'}
        component="a"
        href="/"
        title="SCADA"
        // width={{ xs: 100, md: 120 }}
        sx={{
          fontSize: '1.5rem',
          lineHeight: '1.5',
          fontWeight: '700',
          color: '#0057af',
          textDecoration: 'none',
        }}
      >
        Thai Open SCADA
        {/* <Box
          component={'img'}
          src={
            mode === 'light' && !colorInvert
              ? 'https://assets.maccarianagency.com/the-front/logos/logo.svg'
              : 'https://assets.maccarianagency.com/the-front/logos/logo-negative.svg'
          }
          height={1}
          width={1}
        /> */}
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'flex' } }} alignItems={'center'}>
        <Box href="/about" component="a" sx={{ textDecoration: 'none', px: 2 }}>
          <Button
            fontWeight={400}
            // fontWeight={openedPopoverId === id || hasActiveLink() ? 700 : 400}
            // color={'text.primary'}
          >
            About
          </Button>
        </Box>

        <Box
          href="/project"
          component="a"
          sx={{ textDecoration: 'none', px: 2 }}
        >
          <Button fontWeight={400}>Platform</Button>
        </Box>

        <Button
          id="basic-button"
          aria-controls="basic-menu"
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          style={{ fontWeight: 'bold' }}
        >
          Smart City
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem
            color="primary"
            onClick={() => {
              window.location = '/smart-city/cyber-physical-system';
              handleClose();
            }}
          >
            <Typography color="primary">Cyber Physical System</Typography>
          </MenuItem>
          <MenuItem
            color="primary"
            onClick={() => {
              window.location = '/smart-city/smart-home';
              handleClose();
            }}
          >
            <Typography color="primary">Smart Home</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              window.location = '/smart-city/smart-meter';
              handleClose();
            }}
          >
            <Typography color="primary">Smart Meter</Typography>
          </MenuItem>
        </Menu>

        <Box href="/news" component="a" sx={{ textDecoration: 'none', px: 2 }}>
          <Button fontWeight={400}>News</Button>
        </Box>

        <Box
          href="/download"
          component="a"
          sx={{ textDecoration: 'none', px: 2 }}
        >
          <Button fontWeight={400}>Download</Button>
        </Box>

        {/* <Box>
          <NavItem
            title={'Landings'}
            id={'landing-pages'}
            items={landingPages}
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={'Company'}
            id={'company-pages'}
            items={companyPages}
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={'Account'}
            id={'account-pages'}
            items={accountPages}
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={'Pages'}
            id={'secondary-pages'}
            items={secondaryPages}
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={'Blog'}
            id={'blog-pages'}
            items={blogPages}
            colorInvert={colorInvert}
          />
        </Box>
        <Box marginLeft={4}>
          <NavItem
            title={'Portfolio'}
            id={'portfolio-pages'}
            items={portfolioPages}
            colorInvert={colorInvert}
          />
        </Box> */}
        {/* <Box marginLeft={4}> */}
        {/* <Button
            variant="contained"
            color="primary"
            component="a"
            target="blank"
            href="https://mui.com/store/items/the-front-landing-page/"
            size="large"
          >
            Buy now
          </Button> */}
        {/* </Box> */}
      </Box>
      <Box sx={{ display: { xs: 'block', md: 'none' } }} alignItems={'center'}>
        <Button
          onClick={() => onSidebarOpen()}
          aria-label="Menu"
          variant={'outlined'}
          sx={{
            borderRadius: 2,
            minWidth: 'auto',
            padding: 1,
            borderColor: alpha(theme.palette.divider, 0.2),
          }}
        >
          <MenuIcon />
        </Button>
      </Box>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  pages: PropTypes.object,
  colorInvert: PropTypes.bool,
};

export default Topbar;
